import { css } from "@emotion/react";
import styled from "@emotion/styled";

const dynamicStyleSectionHeader = (props) =>
  css`
    font-size: ${props.fontSize};
    line-height: ${props.lineHeight};
    letter-spacing: ${props.letterSpacing};
  `;

const dynamicHeaderWrapper = (props) =>
  css`
    top: ${props.top};
  `;

export const HeaderWrapper = styled.div`
  ${dynamicHeaderWrapper};
  position: absolute;
`;

export const SectionHeader = styled.h1`
  ${dynamicStyleSectionHeader};

  color: #3d3d3d;
  margin: 2rem 0 0 0;
  .blue-text {
    color: #243d82;
  }
  .beige-text {
    color: #d9d1c7;
  }
  .white-text {
    color: #ffffff;
  }
  .darkgray-text {
    color: #3d3d3d;
  }
  .darkgray-text {
    color: #3d3d3d;
  }
  .secondary-blue-text {
    color: #1c2854;
  }
`;

export const SectionSubheader = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: normal;
  color: #3d3d3d;
  margin: 25px 0 0 0;
  max-width: 400px;
`;

const clippedDivDynamicProps = ({
  bgColor = "primaryBlue",
  clipPathValues = "polygon(0 0, 0 100%, 100% 100%)",
}) =>
  css`
    background-color: ${bgColor};
    clip-path: ${clipPathValues};
  `;

export const ClippedDiv = styled.div`
  ${clippedDivDynamicProps};
  /* background-color: "primaryBlue";
  clip-path: polygon(0 0, 0 100%, 100% 100%); */
`;

export const Button = styled.button`
  width: 271px;
  height: 62px;
  background-color: #e6ea75;
  font-size: 18px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 1px;
  color: #243d82;
  border-radius: 10px;
  border: 1px solid transparent;
`;
