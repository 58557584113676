import { IoCloseSharp } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { Container, useThemeUI } from "theme-ui";
import { useBreakpointIndex } from "@theme-ui/match-media";

const Navigation = ({ handleMobileMenuState, isMobileMenuOpen, navLinks }) => {
  const { theme } = useThemeUI();
  const currentBreakpoint = useBreakpointIndex();

  return (
    <Container as="nav" variant="containerNavbar">
      {currentBreakpoint > 2 ? (
        <Container id="linksContainer" variant="containerLinks">
          {navLinks}
        </Container>
      ) : (
        <Container>
          {!isMobileMenuOpen ? (
            <GiHamburgerMenu
              onClick={handleMobileMenuState}
              fontSize="42px"
              color={theme.colors.primaryBlue}
              id="hamburger-menu-icon"
            />
          ) : (
            <IoCloseSharp
              onClick={handleMobileMenuState}
              fontSize="42px"
              color={theme.colors.beige}
              id="close-hamburger-menu-icon"
            />
          )}
        </Container>
      )}
    </Container>
  );
};

export default Navigation;
