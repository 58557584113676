import React from "react";
import { Tween } from "react-gsap";
import { Heading, Text } from "theme-ui";

import { HeaderWrapper, SectionHeader } from "../ui/styled";

export const isBrowserEnvironment = () => typeof window !== "undefined";

export const ProgressTracker = ({ progress }) => (
  <div style={{ position: "absolute", top: "-100px" }}>
    <Heading>Scroll Progress: {progress.toFixed(3)}</Heading>
  </div>
);

export const filterValidReactChildren = (reactChildren) =>
  React.Children.toArray(reactChildren).filter((child) =>
    React.isValidElement(child),
  );

// It takes a template literal and returns a function that receives an object with the
// keys and values needed to make substitutions in the template literal passed.
// The function separates the strings from the expressions, evaluate expressions and
// returns the final string.
export const literalTemplater = (strings, ...keys) => {
  return (content) => {
    const copyOfStrings = strings.slice();
    keys.forEach((key, index) => {
      copyOfStrings[index] = copyOfStrings[index] + content[key];
    });
    return copyOfStrings.join("");
  };
};

// Get a function with the template literal ready to receive the variables to substitute.
export const headerTemplate = literalTemplater`<span class="${"topStringClassName"}">${"topString"}</span><br />
<span class="${"bottomStringClassName"}">${"bottomString"}</span>`;

// Maps an array with the copy for headers and subheaders and returns a consistent markup structure
export const createHeaders = ({
  currentHeaderFontSize,
  currentHeaderFontSizeLongText,
  currentLineHeight,
  currentLineHeightLongText,
  headers = [],
  subheaders,
  type = "header",
  top = "100px",
}) => {
  const result = headers.map((header, index) => {
    return (
      <HeaderWrapper
        className={
          index === 0 ? "animate__animated animate__slideInLeft" : null
        }
        key={`key-scene-div${index}`}
        id={`scene-div-${index}-${type}`}
        top={top}>
        <SectionHeader
          fontSize={
            index === 3 ? currentHeaderFontSizeLongText : currentHeaderFontSize
          }
          lineHeight={
            index === 3 ? currentLineHeightLongText : currentLineHeight
          }
          letterSpacing="normal"
          dangerouslySetInnerHTML={{
            __html: headerTemplate(header),
          }}
        />
        <Text variant="subHeader">{subheaders[index].content}</Text>
        {subheaders[index].addOn ? subheaders[index].addOn : null}
      </HeaderWrapper>
    );
  });
  return result;
};

export const createTimelineLabels = (headers = [], prefixLabel = "header") => {
  const labels = headers.map((_, i) => ({
    label: `${prefixLabel}-${i}`,
    position: i,
  }));

  return labels;
};

export const switchToOverlay = () => {
  const mainContainer = document.getElementById("all-triangles-container");
  const overlayContainer = document.getElementById("overlay-container");
  const currentStateMainContainer = mainContainer.style.display;
  const currentStateOverlayContainer = overlayContainer.style.display;
  mainContainer.style.display =
    currentStateMainContainer === "none" ? "block" : "none";
  overlayContainer.style.display =
    currentStateOverlayContainer === "none" ? "block" : "none";
};

export const createTween = (vars) => <Tween {...vars} />;

export const createHeaderTweens = (headers = [], type = "header") => {
  const tweenHeaders = headers.reduce((accum, _, index, headers) => {
    accum.push(
      index !== 0 &&
        createTween({
          key: `key-${type}-${index}`,
          // Make the first header start visible in x=0 and then go to opacity 0
          from: {
            x: index === 0 ? 0 : -150,
            opacity: index === 0 ? 1 : 0,
            ease: "back",
            duration: 5,
          },
          target: index,
          // position,
          onComplete: index === 5 ? switchToOverlay : () => {},
          onStart:
            index === 0
              ? () => {
                  document
                    .getElementById("scene-div-0-header")
                    .removeAttribute("style");
                }
              : index === 5
              ? () => {
                  document
                    .getElementById("layout-container")
                    .classList.remove("bgcolorTranslateBack");

                  document
                    .getElementById("main-wrapper")
                    .classList.remove("bgcolorTranslateBack");
                  document
                    .getElementById("layout-container")
                    .classList.add("bgcolorTranslate");

                  document
                    .getElementById("main-wrapper")
                    .classList.add("bgcolorTranslate");

                  // Get the divs containing the triangles
                  const collectionTriangles = document.getElementsByClassName(
                    "divTriangle",
                  );

                  const copyrightContainer = document.getElementById(
                    "copyrightContainer",
                  );
                  copyrightContainer.style.opacity = 1;

                  if (document.getElementById("close-hamburger-menu-icon")) {
                    const closeHamburgerIcon = document.getElementById(
                      "close-hamburger-menu-icon",
                    );

                    closeHamburgerIcon.setAttributeNS(null, "color", "#ffffff");
                    closeHamburgerIcon.setAttributeNS(null, "style", {
                      color: "#ffffff",
                    });
                  }

                  if (document.getElementById("hamburger-menu-icon")) {
                    const hamburgerIcon = document.getElementById(
                      "hamburger-menu-icon",
                    );

                    hamburgerIcon.setAttributeNS(null, "color", "#ffffff");
                    hamburgerIcon.setAttributeNS(null, "style", {
                      color: "#ffffff",
                    });
                  }

                  // switch the bg color to white
                  Array.prototype.map.call(collectionTriangles, (triangle) => {
                    triangle.style.backgroundColor = "#ffffff";
                  });

                  // Switch the logo at the top
                  document.getElementById("logoWhite").style.display =
                    "inline-block";
                  document.getElementById("logoBlue").style.display = "none";

                  // Switch the color of the nav bar
                  if (document.getElementById("linksContainer")) {
                    document.getElementById("linksContainer").style.color =
                      "#ffffff";
                  }
                }
              : () => {},
          onReverseComplete:
            index === 5
              ? () => {
                  switchToOverlay();
                  document
                    .getElementById("main-wrapper")
                    .classList.remove("bgcolorTranslate");
                  document
                    .getElementById("layout-container")
                    .classList.remove("bgcolorTranslate");

                  document
                    .getElementById("main-wrapper")
                    .classList.add("bgcolorTranslateBack");
                  document
                    .getElementById("layout-container")
                    .classList.add("bgcolorTranslateBack");

                  const collectionTriangles = document.getElementsByClassName(
                    "divTriangle",
                  );
                  Array.prototype.map.call(collectionTriangles, (triangle) => {
                    triangle.style.backgroundColor = "#243D82";
                  });

                  const copyrightContainer = document.getElementById(
                    "copyrightContainer",
                  );
                  copyrightContainer.style.opacity = 0;

                  if (document.getElementById("close-hamburger-menu-icon")) {
                    const closeHamburgerIcon = document.getElementById(
                      "close-hamburger-menu-icon",
                    );

                    closeHamburgerIcon.setAttributeNS(null, "color", "#243D82");
                    closeHamburgerIcon.setAttributeNS(null, "style", {
                      color: "#243D82",
                    });
                  }

                  if (document.getElementById("hamburger-menu-icon")) {
                    const hamburgerIcon = document.getElementById(
                      "hamburger-menu-icon",
                    );

                    hamburgerIcon.setAttributeNS(null, "color", "#243D82");
                    hamburgerIcon.setAttributeNS(null, "style", {
                      color: "#243D82",
                    });
                  }

                  // Switch the logo at the top
                  document.getElementById("logoBlue").style.display =
                    "inline-block";
                  document.getElementById("logoWhite").style.display = "none";

                  // Switch the color of the nav bar
                  if (document.getElementById("linksContainer")) {
                    document.getElementById("linksContainer").style.color =
                      "#243D82";
                  }
                }
              : () => {},
        }),
    );
    // Create tweens to fade out headers, do not create one for the last header
    if (index + 1 < headers.length) {
      accum.push(
        createTween({
          key: `key-${type}-${index}-exit`,
          to: { opacity: 0 },
          target: index,
          position: index !== 0 ? `header-${index}+=6` : `header-${index}+=3`,
          duration: 2,
        }),
      );
    }

    return accum;
  }, []);

  return tweenHeaders;
};
