// import React from "react";
import React, { useState } from "react";
import { Box, Container, Image, NavLink } from "theme-ui";

import Navigation from "./Navigation";
import Logo from "../../assets/images/Blue.png";
import LogoWhite from "../../assets/images/White.png";

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuState] = useState(false);
  const handleMobileMenuState = () => setMobileMenuState(!isMobileMenuOpen);

  const navItems = [
    { title: "Home", link: "../home" },
    { title: "Products", link: "../page/products" },
    { title: "Services", link: "../page/services" },
    { title: "Get in touch", link: "../page/get-in-touch" },
  ];

  const navLinks = navItems.map((item) => (
    <NavLink
      padding={["0", "0", "0", "1.5rem", "1.5rem", "1.5rem"]}
      key={item.title}
      href={item.link}
    >
      {item.title}
    </NavLink>
  ));

  return (
    <Container
      variant="flexContainerColumn"
      style={{ position: "sticky", top: "0", marginTop: "20px" }}
    >
      <Container as="header" variant="containerHeader">
        <Box id="logoContainer" style={{ flex: "0 0 auto" }}>
          <a href="/home">
            <Image id="logoBlue" src={Logo} variant="logo" />
            <Image
              id="logoWhite"
              src={LogoWhite}
              variant="logo"
              style={{ display: "none" }}
            />
          </a>
        </Box>
        <Navigation
          handleMobileMenuState={handleMobileMenuState}
          isMobileMenuOpen={isMobileMenuOpen}
          navLinks={navLinks}
        />
      </Container>
      {isMobileMenuOpen && (
        <Container as="nav" variant="containerMobileLinks">
          {navLinks}
        </Container>
      )}
    </Container>
  );
};

export default Navbar;
