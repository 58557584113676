/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { useResponsiveValue } from "@theme-ui/match-media";
import { Fragment, useEffect, useRef } from "react";
import { Timeline, Tween } from "react-gsap";
import { animateScroll as scroll } from "react-scroll";
import { Controller, Scene } from "react-scrollmagic";
import { Container, Flex, Text } from "theme-ui";
import { isAndroid } from "react-device-detect";

import { copyright, headers, subheaders } from "../../assets/copy";
import overlayAnimation_png from "../../assets/images/overlayAnimation.png";
import overlayAnimation_webp from "../../assets/images/overlayAnimation.webp";
import "../../assets/modernizr-custom";
import {
  createHeaders,
  createHeaderTweens,
  createTimelineLabels,
} from "../utils/utils";

import IScroll from "../utils/iscroll-probe";

const { Modernizr } = window;

const SupportedContainer = styled(Container)`
  -webkit-user-drag: auto;
  pointer-events: auto;
`;

const ContainerTriangles = styled.div`
  width: ${({ currentSizeOfTrianglesContainer }) =>
    `${currentSizeOfTrianglesContainer}px`};
  height: ${({ currentSizeOfTrianglesContainer }) =>
    `${currentSizeOfTrianglesContainer}px`};
  position: relative;
  /* border: 1px solid green; */
`;

const PageContent = () => {
  const myScroll = useRef(null);

  useEffect(() => {
    scroll.scrollTo("12000", {
      delay: 3000,
      duration: 45000,
      smooth: "linear",
      containerId: "main-container",
    });

    if (isAndroid) {
      const handler = setTimeout(() => {
        myScroll.current = new IScroll("#main-container", {
          // don't scroll horizontal
          scrollX: false,
          // but do scroll vertical
          scrollY: true,
          // show scrollbars
          scrollbars: true,
          // deactivating -webkit-transform because pin wouldn't work because of a webkit bug: https://code.google.com/p/chromium/issues/detail?id=20574
          // if you dont use pinning, keep "useTransform" set to true, as it is far better in terms of performance.
          useTransform: false,
          // deativate css-transition to force requestAnimationFrame (implicit with probeType 3)
          useTransition: false,
          // set to highest probing level to get scroll events even during momentum and bounce
          // requires inclusion of iscroll-probe.js
          probeType: 3,
          // pass through clicks inside scroll container
          click: true,
        });
      }, 0);

      return () => clearTimeout(handler);
    }

    // eslint-disable-next-line no-unused-vars
  }, []);

  // Set font attributes dynamically depending on current screen size
  const currentHeaderFontSize = useResponsiveValue((theme) => [
    "26px",
    "30px",
    "42px",
    "72px",
    "66px",
    "72px",
  ]);

  const currentHeaderFontSizeLongText = useResponsiveValue((theme) => [
    "22px",
    "26px",
    "30px",
    "60px",
    "44px",
    "60px",
  ]);

  const currentLineHeight = useResponsiveValue((theme) => [
    "normal",
    "normal",
    "40px",
    "70px",
    "1em",
    "70px",
  ]);
  const currentLineHeightLongText = useResponsiveValue((theme) => [
    "1em",
    "1em",
    "1em",
    "1em",
    "1em",
    "1em",
  ]);
  const currentMarginTopTriangles = useResponsiveValue((theme) => [
    "5",
    "5",
    "20",
    "30",
    "0",
    "0",
  ]);
  const currentMarginLeftTriangles = useResponsiveValue((theme) => [
    "0",
    "0",
    "0",
    "0",
    "32",
    "32",
  ]);
  const currentSizeOfLargeTriangle = useResponsiveValue((theme) => [
    "120",
    "160",
    "200",
    "320",
    "280",
    "460",
  ]);
  const currentSizeOfSmallTriangle = useResponsiveValue((theme) => [
    "60",
    "80",
    "100",
    "160",
    "140",
    "230",
  ]);

  const currentSizeOfTrianglesContainer = currentSizeOfSmallTriangle * 3;

  const currentTopOffsetPositionForHeaders = useResponsiveValue((theme) => [
    "0",
    "0",
    "10px",
    "30px",
    "calc((100vh - 65%) / 2)",
    "calc((100vh - 50%) / 2)",
  ]);

  const currentHeightOfAnimationContainers = useResponsiveValue((theme) => [
    ["30vh", "47vh"],
    ["30vh", "49vh"],
    ["30vh", "54vh"],
    ["30vh", "54vh"],
    ["83vh", "86vh"],
    ["85vh", "89vh"],
  ]);

  const currentFlexAlignmentTrianglesSection = useResponsiveValue((theme) => [
    "flex-start",
    "flex-start",
    "flex-start",
    "flex-start",
    "center",
    "center",
  ]);

  const initialBackgroundStylesTriangles = {
    backgroundImage: "none",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const initialStylePropsTriangles = {
    ...initialBackgroundStylesTriangles,
    opacity: 0,
    width: `${currentSizeOfSmallTriangle}px`,
    height: `${currentSizeOfSmallTriangle}px`,
    backgroundColor: "#243D82",
    clipPath: "polygon(0 0, 0 100%, 100% 100%)",
    position: "absolute",
  };

  const trianglePositions = [
    /**
     * start with opacity 0
     * translate to top = 1/2 its size and left = its size * 2.25
     */
    {
      left: currentSizeOfSmallTriangle * 2.25,
      top: (currentSizeOfSmallTriangle / 2) * -1,
    },
    {
      left: "0",
      top: "0",
    },
    {
      left: currentSizeOfSmallTriangle * 0.8,
      top: currentSizeOfSmallTriangle * 0.55 * -1,
    },
    {
      right: "0",
      bottom: "0",
    },
    {
      left: currentSizeOfSmallTriangle * 1,
      top: currentSizeOfSmallTriangle * 1,
    },
    {
      bottom: "0",
    },
  ];

  return (
    // Container for Header/Sub-header group and triangles
    <SupportedContainer
      className="main-container"
      id="main-container"
      as="main"
      variant="containerMain"
    >
      {/** Scrollmagic Controller and Scene */}
      <Controller container="#main-container">
        <Scene
          duration="800%"
          indicators={false} // set to true to debug
          triggerHook="onLeave" // rel pos on the screen to hit for triggers
          pin
        >
          {(progress) => (
            <Flex
              id="scene-container"
              sx={{
                flexDirection: [
                  "column",
                  "column",
                  "column",
                  "column",
                  "row",
                  "row",
                ],
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Timeline
                paused
                progress={progress}
                labels={[...createTimelineLabels(headers)]}
              >
                {/** HEADERS AND CTA SECTION */}
                <section
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: currentHeightOfAnimationContainers[0],
                    // flex: "55%",
                  }}
                >
                  {/** All the headers */}
                  <Timeline
                    paused
                    progress={progress}
                    target={
                      <Fragment>
                        {createHeaders({
                          currentHeaderFontSize,
                          currentHeaderFontSizeLongText,
                          currentLineHeight,
                          currentLineHeightLongText,
                          headers: headers,
                          subheaders: subheaders,
                          top: currentTopOffsetPositionForHeaders,
                        })}
                      </Fragment>
                    }
                  >
                    {createHeaderTweens(headers)}
                  </Timeline>
                  <Text
                    id="copyrightContainer"
                    variant="copyright"
                    style={{
                      opacity: 0,
                      color: "#ffffff",
                      position: "absolute",
                      bottom: "0",
                    }}
                  >
                    {copyright}
                  </Text>
                </section>

                {/** TRIANGLES SECTION */}
                <section
                  style={{
                    display: "flex",
                    justifyContent: currentFlexAlignmentTrianglesSection,
                    flexDirection: "column",
                    // flex: "45%",
                    width: "100%",
                    height: currentHeightOfAnimationContainers[1],
                    marginLeft: `${currentMarginLeftTriangles}px`,
                    marginTop: `${currentMarginTopTriangles}px`,
                  }}
                >
                  <ContainerTriangles
                    id="overlay-container"
                    currentSizeOfTrianglesContainer={
                      currentSizeOfTrianglesContainer
                    }
                    style={{ display: "none" }}
                  >
                    <img
                      src={
                        Modernizr && Modernizr.webp && Modernizr.webp.animation
                          ? overlayAnimation_webp
                          : overlayAnimation_png
                      }
                      width="100%"
                      alt=""
                    />
                  </ContainerTriangles>
                  <ContainerTriangles
                    style={{ display: "block" }}
                    currentSizeOfTrianglesContainer={
                      currentSizeOfTrianglesContainer
                    }
                    id="all-triangles-container"
                  >
                    <Timeline
                      paused
                      progress={progress}
                      // position="0"
                      target={
                        <Fragment>
                          <div
                            className="divTriangle"
                            id="tr1"
                            style={{
                              ...initialStylePropsTriangles,
                              clipPath: "polygon(100% 0, 0 100%, 100% 100%)",
                              ...trianglePositions[0],
                            }}
                          />
                          <div
                            className="divTriangle"
                            id="tr2"
                            style={{
                              ...initialStylePropsTriangles,
                              ...trianglePositions[1],
                            }}
                          />
                          <div
                            className="divTriangle"
                            id="tr3"
                            style={{
                              ...initialStylePropsTriangles,
                              backgroundColor: "#D9D1C7",
                              ...trianglePositions[2],
                            }}
                          />
                          <div
                            className="divTriangle"
                            id="tr4"
                            style={{
                              ...initialStylePropsTriangles,
                              ...trianglePositions[3],
                            }}
                          />
                          <div
                            className="divTriangle"
                            id="tr5"
                            style={{
                              ...initialStylePropsTriangles,
                              ...trianglePositions[4],
                            }}
                          />
                          <div
                            className="divTriangle"
                            id="tr6"
                            style={{
                              ...initialStylePropsTriangles,
                              width: `${currentSizeOfLargeTriangle}px`,
                              height: `${currentSizeOfLargeTriangle}px`,
                              ...trianglePositions[5],
                            }}
                          />
                        </Fragment>
                      }
                    >
                      {/** TWEENS */}
                      {/** TRIANGLE index 0 */}
                      <Tween
                        to={{ opacity: 1 }}
                        target={0}
                        position="1"
                        duration="1"
                      />
                      <Tween
                        to={{ rotateY: "180" }}
                        target={0}
                        position="2"
                        duration="0.5"
                      />
                      <Tween
                        to={{
                          y: currentSizeOfSmallTriangle / 2,
                          x: (currentSizeOfSmallTriangle / 4) * -1,
                        }}
                        target={0}
                        position=">0.1"
                        duration="0.5"
                      />

                      {/** TRIANGLE index 1 */}
                      <Tween
                        to={{ opacity: 1 }}
                        target={1}
                        position="2"
                        duration="1"
                      />

                      {/** TRIANGLE index 2 */}
                      <Tween
                        to={{ opacity: 1 }}
                        target={2}
                        position="4"
                        duration="1"
                      />
                      <Tween
                        to={{
                          y: currentSizeOfSmallTriangle * 0.55,
                          x: currentSizeOfSmallTriangle * 0.2,
                        }}
                        target={2}
                        position="4"
                        duration="0.3"
                      />

                      {/** TRIANGLE index 3 */}
                      <Tween
                        to={{ opacity: 1 }}
                        target={3}
                        position="3"
                        duration="1"
                      />
                      <Tween
                        to={{ y: currentSizeOfSmallTriangle * -1 }}
                        target={3}
                        position="5"
                        duration="0.5"
                      />

                      <Tween
                        to={{
                          y: currentSizeOfSmallTriangle * 1,
                          x: currentSizeOfSmallTriangle * 1,
                        }}
                        target={4}
                        position="5"
                        duration="0.5"
                      />

                      {/** TRIANGLE index 4 */}
                      <Tween
                        to={{ opacity: 1 }}
                        target={4}
                        position="4"
                        duration="1"
                      />

                      {/** TRIANGLE index 5 */}
                      <Tween
                        to={{ opacity: 1 }}
                        target={5}
                        position="0"
                        duration="1"
                      />
                    </Timeline>
                  </ContainerTriangles>
                </section>
              </Timeline>
            </Flex>
          )}
        </Scene>
      </Controller>
    </SupportedContainer>
  );
};

export default PageContent;
