import styled from "@emotion/styled";

import Navbar from "../navbar/Navbar";
import PageContent from "./PageContent";
// import Playground from "./playground";

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 1440px;
  padding: 2.2rem;
  margin: 0 auto;
  background-color: #ffffff;

  &.bgcolorTranslate {
    -webkit-transition: 3s;
    -moz-transition: 3s;
    -ms-transition: 3s;
    -o-transition: 3s;
    transition: 3s;
    background-color: #243d82;
  }

  &.bgcolorTranslateBack {
    -webkit-transition: 3s;
    -moz-transition: 3s;
    -ms-transition: 3s;
    -o-transition: 3s;
    transition: 3s;
    background-color: #ffffff;
  }
`;

const MainWrapper = styled.main`
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  -webkit-overflow-scrolling: touch;

  &.bgcolorTranslate {
    -webkit-transition: 3s;
    -moz-transition: 3s;
    -ms-transition: 3s;
    -o-transition: 3s;
    transition: 3s;
    background-color: #243d82;
  }

  &.bgcolorTranslateBack {
    -webkit-transition: 3s;
    -moz-transition: 3s;
    -ms-transition: 3s;
    -o-transition: 3s;
    transition: 3s;
    background-color: #ffffff;
  }
`;

const Layout = () => {
  return (
    <MainWrapper id="main-wrapper">
      <LayoutContainer id="layout-container">
        <Navbar />
        <PageContent />
      </LayoutContainer>
    </MainWrapper>
  );
};

export default Layout;
