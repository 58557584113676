const theme = {
  fonts: {
    arimo: "Arimo, sans-serif",
  },
  colors: {
    primaryBlue: "#243D82",
    secondaryBlue: "#1C2854",
    beige: "#D9D1C7",
    background: "#fff",
    black: "#000",
    darkgray: "#3d3d3d",
    white: "#fff",
  },

  // min-width breakpoints (mobile first)
  breakpoints: ["375px", "413px", "768px", "1023px", "1400px", "1921px"],
  useBorderBox: "true",
  images: {
    logo: {
      width: ["177px", "177px", "177px", "208px", "208px", "208px"],
      height: "auto",
    },
  },
  buttons: {
    primary: {
      width: ["200px", "200px", "200px", "271px", "271px", "271px"],
      height: ["42px", "42px", "62px", "62px", "62px", "62px"],
      backgroundColor: "#e6ea75",
      fontSize: ["16px", "16px", "18px", "18px", "18px", "18px"],
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontWeight: 500,
      lineHeight: "23px",
      letterSpacing: "1px",
      color: "#243d82",
      borderRadius: "10px",
      border: "1px solid transparent",
      marginTop: ["16px", "20px", "16px", "10px", "0", "0"],
    },
  },
  layout: {
    triangle: {
      backgroundColor: "primaryBlue",
      clipPath: "polygon(0 0, 0 100%, 100% 100%)",
    },
    containerMobileLinks: {
      marginTop: "1rem",
      padding: ["1rem", "1rem", "1rem", "1rem", "1rem", "1rem"],
      fontSize: ["16px", "16px", "16px", "24px", "24px", "24px"],
      fontFamily: "arimo",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "flex-start",
      flexDirection: "column",
      height: ["30vh", "30vh", "30vh", "30vh", "30vh", "30vh"],
      color: "beige",
      background: "linear-gradient(225.02deg, #2B3E84 0%, #1C2854 100%)",
    },
    containerLinks: {
      flex: "1",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginLeft: ["3rem", "3rem", "3rem", "1rem", "3rem", "3rem"],
      width: "95%",
      color: "primaryBlue",
    },
    containerNavbar: {
      textAlign: ["right", "right", "right", "left", "left", "left"],
    },
    containerHeader: {
      display: "flex",
      alignItems: "center",
    },
    containerMain: {
      overflow: "auto",
      width: "100%",
      height: "100vh",
      position: "relative",
    },
    flexContainerColumn: {
      display: "flex",
      flexDirection: "column",
      marginTop: "5px",
    },
    scrollMoreContainer: {
      display: "flex",
      flexDirection: "column",
      marginTop: "5px",
      width: "100px",
      height: "100px",
      position: "relative",
    },
  },
  links: {
    nav: {
      padding: "1.5rem",
    },
  },
  styles: {
    root: {
      margin: 0,
      fontFamily: "'Arimo', Helvetica, sans-serif",
      fontWeight: 400,
    },
  },
  text: {
    ctaText: {
      color: "primaryBlue",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.88px",
      margin: "1.25rem 0 0 0",
    },
    subHeader: {
      fontSize: ["16px", "16px", "16px", "20px", "18px", "20px"],
      fontWeight: "300",
      lineHeight: ["20px", "20px", "24px", "30px", "25px", "30px"],
      letterSpacing: "normal",
      color: "#3d3d3d",
      margin: [
        "5px 0 0 0",
        "5px 0 0 0",
        "16px 0 0 0",
        "25px 0 0 0",
        "16px 0 0 0",
        "25px 0 0 0",
      ],
      maxWidth: ["400px", "250px", "400px", "400px", "450px", "500px"],
    },
    copyright: {
      fontSize: ["12px", "12px", "14px", "14px", "16px", "16px"],
      maxWidth: ["320px", "320px", "400px", "450px", "500px", "500px"],
    },
    scrollMoreCopy: {
      margin: "auto 0.25rem",
      color: "primaryBlue",
      fontSize: ["16px", "16px", "16px", "16px", "20px", "20px"],
    },
  },
};

export default theme;
