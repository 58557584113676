import { Container, Text } from "theme-ui";
import { IoIosArrowDown } from "react-icons/io";

const ScrollMore = () => {
  return (
    <div className="scroll-more-container">
      <Container className="arrow-container">
        <div className="arrow-2">
          <IoIosArrowDown id="iconScrollMore" />
        </div>
        <div className="arrow-1 animate__animated animate__infinite animated hinge zoomIn"></div>
      </Container>
      <Text
        className="animate__animated animate__delay-1s animate__flash"
        variant="scrollMoreCopy">
        Scroll to discover more
      </Text>
    </div>
  );
};

export default ScrollMore;
