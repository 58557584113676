import { Button } from "theme-ui";
import ScrollMore from "../components/ui/ScrollMore";

export const headers = [
  {
    topStringClassName: "dark-text",
    bottomStringClassName: "blue-text",
    topString: "We are",
    bottomString: "veterinarians",
  },
  {
    topStringClassName: "dark-text",
    bottomStringClassName: "secondary-blue-text",
    topString: "We are",
    bottomString: "innovators",
  },
  {
    topStringClassName: "dark-text",
    bottomStringClassName: "blue-text",
    topString: "We are",
    bottomString: "communicators",
  },
  {
    topStringClassName: "dark-text",
    bottomStringClassName: "secondary-blue-text",
    topString: "We are your source for",
    bottomString: "animal health products",
  },
  {
    topStringClassName: "dark-text",
    bottomStringClassName: "blue-text",
    topString: "We are",
    bottomString: "better together",
  },
  {
    topStringClassName: "white-text",
    bottomStringClassName: "white-text",
    topString: "We are South",
    bottomString: "West Vets",
  },
];

export const subheaders = [
  {
    content:
      "Providing expertise focused on swine health, management, and food safety.",
    addOn: <ScrollMore />,
  },
  {
    content:
      "Creating solutions for real world problems across all facets of pork production.",
  },
  {
    content: "Connecting you with training, knowledge, and analytic tools.",
  },
  {
    content: "Providing products to address your individual needs.",
  },
  { content: "" },
  {
    content: (
      <Button variant="primary">
        <a style={{ textDecoration: "none" }} href="/home">
          Enter the Site
        </a>
      </Button>
    ),
  },
];

export const copyright =
  "South West Ontario Veterinary Services © 2021. All Rights Reserved.";
